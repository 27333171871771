import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useParams,
} from "react-router-dom";

import Form from "./components/Form";
import Card from "./components/Card";
import Success from "./components/Success";
import Failed from "./components/Failed";
import MobileView from "./public/previewLinks";

const ProtectRoot = ({ children }) => {
  const { cardId, userId } = useParams();
  console.log(cardId, userId);
  const navigate = useNavigate();
  const id = localStorage.getItem("id");
  const key = localStorage.getItem("key");
  if (id) {
    if (!key || key === "false") {
      return <Navigate to={`/${id}/${cardId}/${userId}`} />;
    } else {
      return children;
    }
  } else {
    navigate("/");
  }
  return <div></div>;
};

const ProtectSuccess = ({ children }) => {
  const response = localStorage.getItem("response");
  return response === "true" ? children : <Navigate to="/" />;
};

const ProtectFailed = ({ children }) => {
  const response = localStorage.getItem("response");
  return response === "false" ? children : <Navigate to="/" />;
};

function App() {
  return (
    <Routes>
      {/* add-new-links */}

      <Route path="/" element={<Navigate to="profile-links" replace />} />
      <Route path="profile-links" element={<MobileView />} />

      {/* add-new-links */}

      <Route
        path="/"
        element={
          <ProtectRoot>
            <Form />
          </ProtectRoot>
        }
      />
      <Route path="/:id/:userId/:cardId" element={<Card />} />
      <Route
        path="/success"
        element={
          <ProtectSuccess>
            <Success />
          </ProtectSuccess>
        }
      />
      <Route
        path="/failed"
        element={
          <ProtectFailed>
            <Failed />
          </ProtectFailed>
        }
      />
      <Route
        path="/404"
        element={
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "30px",
              fontWeight: 700,
            }}
          >
            404 - Card not found
          </div>
        }
      />
    </Routes>
  );
}

export default App;
