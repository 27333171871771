import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import axios from "axios";
import { Button } from '@mui/material';

const CardLink = ({ links, themeColors, cardId }) => {
  const handleLinkCount = async (id) => {
    
    try {
      const body = {
        linkId: id,
        cardId: cardId,
      };
      await axios.post(
        "https://btapped.bverselabs.com/api/cards/tap/link",
        body
      );
    } catch (error) { }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const background = themeColors?.backGroundColor.startsWith('#') ? themeColors.backGroundColor : `#${themeColors?.backGroundColor}` || '#FFDDB6'
const color = themeColors?.color.startsWith('#') ? themeColors.color : `#${themeColors?.color}` || '#FFA845'
  return (
    <>
      {isSmallScreen ? <main>

        {links && links?.map((item) => (
          <Box
            key={item?.id}
            position={"relative"}
            borderRadius={"10px"}
            padding={"20px"}
            textAlign={"center"}
            bgcolor={color}

          >
            <Box
              display={"flex"}
              margin={"auto"}
              padding={0.8}
              bgcolor={"#fff"}
              width={"40px"}
              height={"40px"}
              borderRadius={"50px"}
              border={"1px solid #fff"}
            >
              <img src={item?.icon} alt="" />
            </Box>

            <Typography margin={"4px 0px"} fontSize={"14px"} fontWeight={600}>

              {item?.name}
            </Typography>
            <Button
                onClick={() => handleLinkCount(item?.id)}

                component="a"
                href={item?.url}
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  borderColor: 'black',
                  backgroundColor:{background} ,
                  borderRadius: '4px',
                  padding: '3px 8px',
                  fontSize: '8px'
                }}
              >
                {item?.name}
              </Button>
            {/* <Typography
              fontSize={"11px"}
              fontWeight={400}
              onClick={() => handleLinkCount(item?.id)}
            >
              {" "}
              <a title={item?.url} href={item?.url} target="_blank">
                {" "}
                {item?.url.slice(0, 20)}{" "}
              </a>{" "}
            </Typography> */}
          </Box>
        ))}
      </main> : <Box
        display='flex'
        flexDirection='row'
        justifyContent={isSmallScreen ? 'center' : 'flex-start'}
        alignItems='center'
        gap='20px'
        flexWrap='wrap'
        marginBottom='20px'
      >
        {links && links.map((item) => (
          <Box
            width={isSmallScreen ? '140px' : '200px'}
            height={isSmallScreen ? '160px' : '200px'}
            key={item?.id}
            boxShadow={'2px 2px 5px gray'}
            borderRadius='12px'
            padding={isSmallScreen ? '12px' : '16px'}
            textAlign='center'
            bgcolor={color}
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={isSmallScreen ? '8px' : '12px'}
            sx={{
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '2px 2px 10px gray'
              },
            }}
          >
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              padding={isSmallScreen ? '6px' : '10px'}
              bgcolor='#fff'
              width={isSmallScreen ? '60px' : '70px'}
              height={isSmallScreen ? '60px' : '70px'}
              boxShadow='0 2px 4px rgba(0, 0, 0, 0.2)'
              borderRadius='50%'
              border='2px solid #fff'
            >
              <img
                src={item?.icon}
                alt={item?.name}
                style={{
                  width: isSmallScreen ? '40px' : '50px',
                  height: isSmallScreen ? '40px' : '50px',
                }}
              />
            </Box>
            <Box>
              <Typography
                margin={isSmallScreen ? '4px 0' : '6px 0'}
                fontSize={isSmallScreen ? '14px' : isLargeScreen ? '16px' : '18px'}
                fontWeight={600}
                color={'black'}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} // Ensure text doesn't overflow
              >
                {item?.name}
              </Typography>

              <Button
                onClick={() => handleLinkCount(item?.id)}

                component="a"
                href={item?.url}
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  borderColor: 'black',
                  backgroundColor:{background},
                  borderRadius: '4px',
                  padding: '4px 10px',
                  fontSize: '10px'
                }}
              >
                {item?.name}
              </Button>
             

            </Box>
          </Box>
        ))}
      </Box>}
    </>
  );
};

export default CardLink;
