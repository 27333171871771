import { useLocation } from "react-router-dom";

const Failed = () => {
    const { state } = useLocation();
    localStorage.removeItem('id');
    localStorage.removeItem('data');
    localStorage.removeItem('key');
    return (
        <div className="response-wrapper">
            <div className="response-card" style={{ color: "#fc3e30" }}>
                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="48px" height="48px"><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ "mixBlendMode": "normal" }}><g transform="scale(5.33333,5.33333)"><path d="M44,24c0,11.045 -8.955,20 -20,20c-11.045,0 -20,-8.955 -20,-20c0,-11.045 8.955,-20 20,-20c11.045,0 20,8.955 20,20z" fill="#fc3e30"></path><path d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828,-2.828z" fill="#ffffff"></path><path d="M32.484,29.656l-2.828,2.828l-14.14,-14.14l2.828,-2.828z" fill="#ffffff"></path></g></g></svg>
                <span>
                    {state?.message || 'Failed to add'}
                </span>
            </div>
        </div>
    )
}

export default Failed;