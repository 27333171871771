import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Form = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        setFirstName(value);

        if (!value.trim()) {
            setFirstNameError('First name is required');
        } else {
            setFirstNameError('');
        }
    };

    const handleLastNameChange = (e) => {
        const value = e.target.value;
        setLastName(value);
        if (!value.trim()) {
            setLastNameError('Last name is required');
        } else {
            setLastNameError('');
        }
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        if (!value.trim()) {
            setEmailError('Email is required');
        } else if (!value.match(/^\S+@\S+\.\S+$/)) {
            setEmailError('Invalid email format');
        } else {
            setEmailError('');
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setPhone(value);

        if (!value.trim()) {
            setPhoneError('Phone number is required');
        } else if (!value.match(/^\d{10}$/)) {
            setPhoneError('Invalid phone number (10 digits)');
        } else {
            setPhoneError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasError = false;
        if (!firstName) {
            setFirstNameError('First name is required');
            hasError = true;
        } else {
            setFirstNameError('');
        }

        if (!lastName) {
            setLastNameError('Last name is required');
            hasError = true;
        } else {
            setLastNameError('');
        }

        if (!email) {
            setEmailError('Email is required');
            hasError = true;
        } else {
            setEmailError('');
        }

        if (!phone) {
            setPhoneError('Phone is required');
            hasError = true;
        } else {
            setPhoneError('');
        }

        if (hasError) {
            return;
        }
        setLoading(true);
        const data = {
            firstName,
            lastName,
            email,
            phone,
            userId: JSON.parse(localStorage.getItem('data'))?.user?.id
        }

        try {
            const res = await fetch('https://tapnconnect.codingcopz.in/api/userContacts', {
                body: JSON.stringify(data),
                method: 'POST',
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            const json = await res.json();
            if (json?.isSuccess) {
                localStorage.setItem('response', true);
                setLoading(false);
                navigate('/success', { replace: true });
            } else {
                localStorage.setItem('response', false);
                setLoading(false);
                navigate('/failed', { replace: true });
            }
        } catch (error) {
            setLoading(false);
            navigate('/failed', { replace: true });
        }
    };

    if (loading) {
        return (
            <div className='loading-wrapper'><span className='loader'></span></div>
        )
    }


    return (
        <div className='form-wrapper'>
            <div className='form-content'>
                <h2 className='heading'>Contact Information</h2>
                <form onSubmit={handleSubmit}>
                    <div className='form-field'>
                        <label htmlFor="firstName">First Name</label>
                        <div className='form-control'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17.478 20.1">
                                <path id="person" d="M17.857,11.952a2.523,2.523,0,0,1,2.622,2.412v.8c0,3.17-3.25,6.432-8.739,6.432S3,18.338,3,15.168v-.8a2.523,2.523,0,0,1,2.622-2.412ZM11.739,1.5a4.626,4.626,0,0,1,4.807,4.422,4.626,4.626,0,0,1-4.807,4.422A4.626,4.626,0,0,1,6.933,5.922,4.626,4.626,0,0,1,11.739,1.5Z" transform="translate(-3 -1.5)" fill="#353540" />
                            </svg>
                            <input
                                type="text"
                                id="firstName"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                placeholder='John'
                            />
                        </div>
                        {firstNameError && <div className="error">{firstNameError}</div>}
                    </div>
                    <div className='form-field'>
                        <label htmlFor="lastName">Last Name</label>
                        <div className='form-control'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17.478 20.1">
                                <path id="person" d="M17.857,11.952a2.523,2.523,0,0,1,2.622,2.412v.8c0,3.17-3.25,6.432-8.739,6.432S3,18.338,3,15.168v-.8a2.523,2.523,0,0,1,2.622-2.412ZM11.739,1.5a4.626,4.626,0,0,1,4.807,4.422,4.626,4.626,0,0,1-4.807,4.422A4.626,4.626,0,0,1,6.933,5.922,4.626,4.626,0,0,1,11.739,1.5Z" transform="translate(-3 -1.5)" fill="#353540" />
                            </svg>
                            <input
                                type="text"
                                id="lastName"
                                value={lastName}
                                onChange={handleLastNameChange}
                                placeholder='smith'
                            />
                        </div>
                        {lastNameError && <div className="error">{lastNameError}</div>}
                    </div>
                    <div className='form-field'>
                        <label htmlFor="email">Email Id</label>
                        <div className='form-control'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 18">
                                <path id="Path_3778" data-name="Path 3778" d="M280.939,280A5.056,5.056,0,0,1,286,285.05v7.9a5.058,5.058,0,0,1-5.061,5.05H271.06a5.057,5.057,0,0,1-5.06-5.05v-7.9a5.05,5.05,0,0,1,5.06-5.05Zm1.591,6.54.08-.08a.773.773,0,0,0-.011-1,.841.841,0,0,0-.529-.26.76.76,0,0,0-.561.2L277,289a1.566,1.566,0,0,1-2,0l-4.5-3.6a.765.765,0,0,0-1.071,1.07l.131.13,4.55,3.55a3.149,3.149,0,0,0,3.909,0Z" transform="translate(-266 -280)" fill="#353540" fill-rule="evenodd" />
                            </svg>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder='JohnSmith@gmail.com'
                            />
                        </div>
                        {emailError && <div className="error">{emailError}</div>}
                    </div>
                    <div className='form-field'>
                        <label htmlFor="phone">Phone Number</label>
                        <div className='form-control'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22.003 22">
                                <g id="Group_66130" data-name="Group 66130" transform="translate(-10.501 -9.503)">
                                    <path id="Path_3637" data-name="Path 3637" d="M892.96,403.048c4.62,4.618,5.668-.724,8.609,2.215,2.835,2.835,4.466,3.4.872,6.995-.449.361-3.309,4.712-13.357-5.327s-5.7-12.91-5.34-13.36c3.6-3.6,4.16-1.969,6.995.872C893.68,397.378,888.34,398.429,892.96,403.048Z" transform="translate(-872 -382)" fill="#353540" fill-rule="evenodd" />
                                </g>
                            </svg>
                            <input
                                type="tel"
                                id="phone"
                                value={phone}
                                onChange={handlePhoneChange}
                                placeholder='+91'
                            />
                        </div>
                        {phoneError && <div className="error">{phoneError}</div>}
                    </div>
                    <button type="submit" disabled={loading}>Share</button>
                </form>
            </div>
        </div>
    );
};

export default Form;
