import { Bounce, toast } from "react-toastify";

const success = (message) => {
  return toast.success(message, {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
    progressClassName: 'Toastify__progress-bar--custom',
  });
};

const error = (message) => {
  return toast.error(message, {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    transition: Bounce,

  });
};

const toster = { success, error };

export default toster;
