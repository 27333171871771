import { useEffect, useRef, useState } from "react";
import CardLink from "./myLinksCard/cardLink";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { joinValidation } from "../validation/validation";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import toster from "../common/toster";
import { useNavigate } from "react-router-dom";

const MobileView = () => {
  const videoRef = useRef(null);

  const youtubeRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [youtubePlayer, setYoutubePlayer] = useState(null);
  const navigate = useNavigate();
  const [showIcon, setShowIcon] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("uniqueLink");
  const [themeColor, setThemeColor] = useState();
  const [data, setData] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  // Check if the screen size is small
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://btapped.bverselabs.com/api/cards/by/uniqueLink?uniqueLink=${type}`
        );
        setData(response.data.data);
        setThemeColor(response?.data?.data?.theme);
      } catch (error) {}
    };

    fetchData();

    return () => {
      // null;
    };
  }, []);

  useEffect(() => {
    if (!type) {
      navigate("/404");
    }
  });

  // const handlePlayClick = () => {
  //   setShowIcon(false);
  //   if (videoRef.current) {
  //     videoRef.current.play();
  //   }
  // };
  // const handlePlay = () => {
  //   setShowIcon(true);
  // };
  // const handlePause = () => {
  //   setShowIcon(false);
  // };
  // const handleVideoClick = () => {
  //   if (videoRef.current) {
  //     if (showIcon) {
  //       videoRef.current.pause();
  //     } else {
  //       videoRef.current.play();
  //     }
  //   }
  // };
  // const handleVideoEnded = () => {
  //   setShowIcon(false);
  //   if (videoRef.current) {
  //     videoRef.current.currentTime = 0;
  //   }
  // };

  useEffect(() => {
    // Initialize YouTube Player
    if (window.YT && window.YT.Player) {
      setPlayer(
        new window.YT.Player(videoRef.current, {
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        })
      );
    } else {
      // If API is not ready yet, load it
      window.onYouTubeIframeAPIReady = () => {
        setPlayer(
          new window.YT.Player(videoRef.current, {
            events: {
              onReady: onPlayerReady,
              onStateChange: onPlayerStateChange,
            },
          })
        );
      };
    }
  }, []);
  useEffect(() => {
    // Initialize YouTube Player
    if (window.YT && window.YT.Player) {
      setYoutubePlayer(
        new window.YT.Player(youtubeRef.current, {
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        })
      );
    } else {
      // If API is not ready yet, load it
      window.onYouTubeIframeAPIReady = () => {
        setYoutubePlayer(
          new window.YT.Player(youtubeRef.current, {
            events: {
              onReady: onPlayerReady,
              onStateChange: onPlayerStateChange,
            },
          })
        );
      };
    }
  }, []);

  const onPlayerReady = (event) => {
    // Player is ready
  };

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      setShowIcon(true);
    } else if (event.data === window.YT.PlayerState.PAUSED) {
      setShowIcon(false);
    } else if (event.data === window.YT.PlayerState.ENDED) {
      setShowIcon(false);
      player.seekTo(0); // Reset to the beginning when video ends
    }
  };

  const handlePlayClick = () => {
    setShowIcon(false);
    if (player) {
      player.playVideo();
    }
  };

  const handleVideoClick = () => {
    if (player) {
      const playerState = player.getPlayerState();
      if (playerState === window.YT.PlayerState.PLAYING) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
    }
  };
  const handleYoutubeVideoClick = () => {
    if (youtubePlayer) {
      const playerState = youtubePlayer.getPlayerState();
      if (playerState === window.YT.PlayerState.PLAYING) {
        youtubePlayer.pauseVideo();
      } else {
        youtubePlayer.playVideo();
      }
    }
  };

  const background = themeColor?.backGroundColor.startsWith("#")
    ? themeColor.backGroundColor
    : `#${themeColor?.backGroundColor}` || "#FFDDB6";
  const color = themeColor?.color.startsWith("#")
    ? themeColor.color
    : `#${themeColor?.color}` || "#FFA845";

  return (
    <>
      {isSmallScreen ? (
        <div className="section">
          <Box bgcolor={background}>
            <Box
              padding={"40px 0px 30px 80px"}
              borderBottom={`1px solid  ${color} `}
            >
              <Box borderRadius={"50px 0px 0px 50px"} bgcolor={color}>
                <div className="profile">
                  <Box
                    width={"60px"}
                    height={"60px"}
                    borderRadius={"50px"}
                    display={"flex"}
                    border={"1px solid #fff"}
                  >
                    <img
                      src={data?.imgUrl || "/images/profile-img.svg"}
                      alt="profile-img"
                    />
                  </Box>

                  <Box>
                    <Typography
                      fontSize={"14px"}
                      color={"#fff"}
                      fontWeight={500}
                      marginBottom={"4px"}
                    >
                      {type}
                    </Typography>
                    <div className="social-icon">
                      <img src="/images/instagram-icon.svg" alt="instagram" />
                      <img src="/images/facebook-icon.svg" alt="facebook" />
                    </div>
                  </Box>
                </div>
              </Box>
            </Box>

            {data &&
              data?.sections?.map((item) => (
                <Box
                  padding={"20px 0px 20px 30px"}
                  borderBottom={`1px solid ${color}`}
                  key={item?.id}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    borderBottom={`1px dashed ${color}`}
                    paddingBottom={"20px"}
                    gap={"10px"}
                    color={"#000"}
                    fontSize={"18px"}
                    fontWeight={600}
                  >
                    <Box
                      width={"34px"}
                      height={"34px"}
                      bgcolor={`${
                        item?.color.startsWith("#")
                          ? item?.color
                          : "#" + item?.color
                      }`}
                      borderRadius={"10px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <img
                        width={"17px"}
                        height={"17px"}
                        src={item?.icon || "/images/book-call-icon.svg"}
                        alt=""
                      />
                    </Box>
                    <div> {item?.title}</div>
                  </Box>

                  {item?.type === "link" ? (
                    <CardLink
                      links={item.links}
                      themeColors={themeColor}
                      cardId={data?.id}
                    />
                  ) : item?.type === "text" ? (
                    <div className="outer">
                      <Box
                        borderRadius={"10px"}
                        padding={"20px"}
                        bgcolor={color}
                        textAlign={"left"}
                      >
                        <Typography fontSize={"16px"} fontWeight={600}>
                          {item?.texts?.title}
                        </Typography>
                        <Typography
                          fontSize={"13px"}
                          color={"rgba(0, 0, 0, 0.7)"}
                        >
                          {item?.texts?.description}
                        </Typography>
                      </Box>
                    </div>
                  ) : item?.type === "embedded" ? (
                    <Box
                      // padding={"20px 0px 20px 30px"}
                      padding={"10px 20px 10px 10px"}
                      // borderBottom={`1px solid ${color}`}
                      key={item?.id}
                    >
                      {/* <Box
                        display={"flex"}
                        alignItems={"center"}
                        borderBottom={`1px dashed ${color}`}
                        paddingBottom={"20px"}
                        gap={"10px"}
                        color={"#000"}
                        fontSize={"18px"}
                        fontWeight={600}
                      >
                        <Box
                          width={"34px"}
                          height={"34px"}
                          bgcolor={"red"}
                          borderRadius={"10px"}
                        >
                          <img src="/images/book-call-icon.svg" alt="" />
                        </Box>
                        <Box fontSize={"18px"}>Youtube Synced....</Box>
                      </Box> */}

                      {item?.embeddeds?.map(
                        (d) => (
                          console.log(d, "emdend link"),
                          (
                            // https://bcoder24.s3.amazonaws.com/meal-box/e8523ff9fabd307972acfb2c39ec18e3bd1df495.mp4
                            <>
                              <iframe
                                ref={videoRef}
                                onClick={handleVideoClick}
                                // onPlay={handlePlay}
                                // onPause={handlePause}
                                // onEnded={handleVideoEnded}
                                height="auto"
                                src={d?.embeddedLink}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{
                                  borderRadius: "20px",
                                  width: '100%'
                                }}
                              ></iframe>

                              {/* {!showIcon && (
                                <img
                                  onClick={handlePlayClick}
                                  src="/images/video-play-icon.svg"
                                  alt=""
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    cursor: "pointer",
                                    width: "50px",
                                    height: "50px",
                                  }}
                                />
                              )} */}
                            </>
                          )
                        )
                      )}

                      {/* <div className="video-section">
                        <video
                          ref={videoRef}
                          onClick={handleVideoClick}
                          onPlay={handlePlay}
                          onPause={handlePause}
                          onEnded={handleVideoEnded}
                          poster="https://assets.codepen.io/32795/poster.png"
                          src="https://bcoder24.s3.amazonaws.com/meal-box/e8523ff9fabd307972acfb2c39ec18e3bd1df495.mp4"
                        ></video>
                        {!showIcon && (
                          <img
                            onClick={handlePlayClick}
                            src="/images/video-play-icon.svg"
                            alt=""
                          />
                        )}
                      </div> */}
                    </Box>
                  ) : item?.type === "video" ? (
                    <Box
                      // padding={"20px 0px 20px 30px"}
                      padding={"10px 20px 10px 10px"}
                      // borderBottom={`1px solid ${color}`}
                      key={item?.id}
                    >
                      {item?.videos?.map(
                        (d) => (
                          console.log(d?.url, "video link"),
                          (
                            // https://bcoder24.s3.amazonaws.com/meal-box/e8523ff9fabd307972acfb2c39ec18e3bd1df495.mp4
                            <>
                              {/* https://www.youtube.com/embed/YRJ6xoiRcpQ */}
                              <iframe
                                ref={youtubeRef}
                                onClick={handleYoutubeVideoClick}
                                // onPlay={handlePlay}
                                // onPause={handlePause}
                                // onEnded={handleVideoEnded}
                                height="auto"
                                src={
                                  "https://www.youtube.com/embed/YRJ6xoiRcpQ"
                                }
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{
                                  borderRadius: "20px",
                                  width: '100%'
                                }}
                              ></iframe>

                              {/* {!showIcon && (
                                <img
                                  onClick={handlePlayClick}
                                  src="/images/video-play-icon.svg"
                                  alt=""
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    cursor: "pointer",
                                    width: "50px",
                                    height: "50px",
                                  }}
                                />
                              )} */}
                            </>
                          )
                        )
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              ))}

            <div className="link-section border-0">
              <Box
                display={"flex"}
                alignItems={"center"}
                borderBottom={`1px dashed ${color}`}
                paddingBottom={"20px"}
                gap={"10px"}
                color={"#000"}
                fontSize={"18px"}
                fontWeight={600}
              >
                <img src="/images/book-call-icon.svg" alt="" />
                <div>Form</div>
              </Box>

              <div className="video-section">
                <div className="white-card">
                  <div className="title">Join our community</div>
                  <div className="description">
                    Please fill all the formality
                  </div>

                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      userName: type || "",
                    }}
                    validationSchema={joinValidation}
                    onSubmit={async (values, { resetForm }) => {
                      const formValues = {
                        ...values,
                      };
                      console.log("Form data", formValues);

                      try {
                        const res = await axios.post(
                          "https://btapped.bverselabs.com/api/forms",
                          formValues,
                          {
                            headers: {
                              "Content-type": "application/json; charset=UTF-8",
                            },
                          }
                        );
                        console.log(res?.data?.isSuccess, "resss");
                        if (res?.data?.isSuccess) {
                          toster.success("Form submitted successfully");
                          resetForm();
                        }
                      } catch (error) {}
                    }}
                  >
                    <Form>
                      <div className="form">
                        <label>Name</label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Name"
                          className="input"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <div className="form">
                        <label>Email</label>
                        <Field
                          type="text"
                          name="email"
                          placeholder="Email"
                          className="input"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <button className="theme-btn" type="submit">
                        Join
                      </button>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </Box>
        </div>
      ) : (
        <Box
          bgcolor={background}
          sx={{
            // backgroundColor: themeColor?.backGroundColor || "#FFA84566",
            display: "flex",
            justifyContent: "center",
            padding: isSmallScreen ? "10px" : "20px",
            height: isSmallScreen ? "none" : "100vh",
            overflow: isSmallScreen ? "none" : "hidden",
          }}
        >
          <Box
            sx={{
              maxWidth: "1280px",
              height: "100%",
              width: "100%",
              paddingX: isSmallScreen ? "3px" : "30px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={isSmallScreen ? "column" : "row"}
              justifyContent={"center"}
              height={"100%"}
              width="100%"
              gap={isSmallScreen ? "20px" : "80px"}
              alignItems={isSmallScreen ? "center" : "none"}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: isSmallScreen ? "center" : "start",
                  padding: "20px",
                  textAlign: isSmallScreen ? "center" : "left",
                }}
              >
                <img
                  src={data?.imgUrl || "/images/profile-img.svg"}
                  alt="profile-img"
                  style={{
                    width: isSmallScreen
                      ? "80px"
                      : isLargeScreen
                      ? "90px"
                      : "100px",
                    height: isSmallScreen
                      ? "80px"
                      : isLargeScreen
                      ? "90px"
                      : "100px",
                    boxShadow: "0px 0px 24px gray",
                    borderRadius: "50%",
                    objectFit: "cover",
                    animation: "spin 2s linear infinite",
                    "@keyframes spin": {
                      "0%": {
                        transform: "rotateZ(0deg)",
                      },
                      "50%": {
                        transform: "rotateZ(15deg)",
                      },
                      "100%": {
                        transform: "rotateZ(0deg)",
                      },
                    },
                  }}
                />
                <Typography
                  fontSize={isSmallScreen ? "16px" : "20px"}
                  color={color}
                  fontWeight={600}
                  marginTop="12px"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {type}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <img
                    src="/images/instagram-icon.svg"
                    alt="instagram"
                    style={{
                      width: "36px",
                      height: "36px",
                      border: `2px solid ${color}`,
                      borderRadius: "50%",
                      boxShadow: "2px 2px 5px gray",
                      transition: "transform 0.3s, box-shadow 0.3s",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = "scale(1.1)";
                      e.currentTarget.style.boxShadow =
                        "0px 6px 15px rgba(0, 0, 0, 0.3)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.boxShadow =
                        "0px 4px 10px rgba(0, 0, 0, 0.2)";
                    }}
                  />
                  <img
                    src="/images/facebook-icon.svg"
                    alt="facebook"
                    style={{
                      width: "36px",
                      height: "36px",
                      border: `2px solid ${color}`,
                      borderRadius: "50%",
                      boxShadow: "2px 2px 5px gray",
                      transition: "transform 0.3s, box-shadow 0.3s",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = "scale(1.1)";
                      e.currentTarget.style.boxShadow =
                        "0px 6px 15px rgba(0, 0, 0, 0.3)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                      e.currentTarget.style.boxShadow =
                        "0px 4px 10px rgba(0, 0, 0, 0.2)";
                    }}
                  />
                </Box>
              </Box>

              <Box
                maxWidth={"900px"}
                sx={{
                  flex: 1,
                  overflowY: "auto",
                }}
              >
                {data &&
                  data?.sections?.map((item) => (
                    <Box
                      padding={isSmallScreen ? "5px" : "10px"}
                      // borderBottom={`1px solid ${themeColor?.color || "#FFA845"}`}
                      key={item?.id}
                    >
                      {item?.links !== 0 ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          // borderBottom={`1px dashed ${themeColor?.color || "#FFA845"}`}
                          paddingBottom="5px"
                          gap="2px"
                          marginBottom={"4px"}
                          color="#000"
                          fontSize={
                            isSmallScreen
                              ? "16px"
                              : isLargeScreen
                              ? "18px"
                              : "22px"
                          }
                          fontWeight={600}
                        >
                          <Box
                            width={isSmallScreen ? "30px" : "34px"}
                            height={isSmallScreen ? "30px" : "34px"}
                            bgcolor={color}
                            borderRadius="10px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              animation: "spin 2s linear infinite",
                              "@keyframes spin": {
                                "0%": {
                                  transform: "rotate(0deg)",
                                },
                                "50%": {
                                  transform: "rotate(20deg)",
                                },
                                "100%": {
                                  transform: "rotate(0deg)",
                                },
                              },
                            }}
                          >
                            <img
                              width={isSmallScreen ? "15px" : "20px"}
                              height={isSmallScreen ? "15px" : "20px"}
                              src={item?.icon || "/images/book-call-icon.svg"}
                              alt=""
                            />
                          </Box>
                          <Box marginLeft={"2px"}>{item?.title}</Box>
                        </Box>
                      ) : null}

                      {item?.type === "link" ? (
                        <CardLink
                          links={item?.links}
                          themeColors={themeColor}
                          cardId={data?.id}
                          // marginBottom='10px'
                        />
                      ) : item?.type === "text" ? (
                        <Box
                          boxShadow={"2px 2px 5px gray"}
                          borderRadius="10px"
                          padding="20px"
                          bgcolor={color}
                          textAlign="left"
                          width="auto"
                          sx={{
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            "&:hover": {
                              transform: "translateY(-5px)",
                              boxShadow: "4px 4px 10px gray",
                            },
                          }}
                          // marginTop="10px"
                        >
                          <Typography
                            fontSize={
                              isSmallScreen
                                ? "14px"
                                : isLargeScreen
                                ? "16px"
                                : "18px"
                            }
                            fontWeight={600}
                          >
                            {item?.texts?.title}
                          </Typography>
                          <Typography
                            fontSize={
                              isSmallScreen
                                ? "11px"
                                : isLargeScreen
                                ? "16px"
                                : "18px"
                            }
                            color="rgba(0, 0, 0, 0.7)"
                          >
                            {item?.texts?.description}
                          </Typography>
                        </Box>
                      ) : item?.type === "embedded" ? (
                        <Box key={item?.id}>
                          <Box
                            display="flex"
                            alignItems="center"
                            paddingBottom="10px"
                            gap="10px"
                            color="#000"
                            fontSize={isSmallScreen ? "16px" : "18px"}
                            fontWeight={600}
                          ></Box>

                          <Box
                            display="flex"
                            justifyContent={isSmallScreen ? "center" : "start"}
                            alignItems="center"
                            position="relative"
                            // marginTop="10px"
                          >
                            {item?.embeddeds?.map(
                              (d) => (
                                console.log(d, "embedded link"),
                                (
                                  // https://bcoder24.s3.amazonaws.com/meal-box/e8523ff9fabd307972acfb2c39ec18e3bd1df495.mp4
                                  <>
                                    <iframe
                                      ref={videoRef}
                                      onClick={handleVideoClick}
                                      // onPlay={handlePlay}
                                      // onPause={handlePause}
                                      // onEnded={handleVideoEnded}
                                      width={isSmallScreen ? "100%" : "auto"}
                                      height="auto"
                                      src={d?.embeddedLink}
                                      title="YouTube video player"
                                      frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen
                                      style={{
                                        width: isSmallScreen ? "100%" : "100%",
                                        maxWidth: isSmallScreen
                                          ? "100%"
                                          : "100%",
                                        borderRadius: "20px",
                                      }}
                                    ></iframe>

                                    {!showIcon && (
                                      <img
                                        onClick={handlePlayClick}
                                        src="/images/video-play-icon.svg"
                                        alt=""
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                          cursor: "pointer",
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              )
                            )}
                          </Box>
                        </Box>
                      ) : item?.type === "video" ? (
                        <Box key={item?.id}>
                          <Box
                            display="flex"
                            alignItems="center"
                            paddingBottom="10px"
                            gap="10px"
                            color="#000"
                            fontSize={isSmallScreen ? "16px" : "18px"}
                            fontWeight={600}
                          ></Box>

                          <Box
                            display="flex"
                            justifyContent={isSmallScreen ? "center" : "start"}
                            alignItems="center"
                            position="relative"
                            // marginTop="10px"
                          >
                            {item?.videos?.map(
                              (d) => (
                                console.log(d?.url, "video link"),
                                (
                                  // https://bcoder24.s3.amazonaws.com/meal-box/e8523ff9fabd307972acfb2c39ec18e3bd1df495.mp4
                                  <>
                                    {/* https://www.youtube.com/embed/YRJ6xoiRcpQ */}
                                    <iframe
                                      ref={videoRef}
                                      onClick={handleVideoClick}
                                      // onPlay={handlePlay}
                                      // onPause={handlePause}
                                      // onEnded={handleVideoEnded}
                                      width={isSmallScreen ? "100%" : "auto"}
                                      height="auto"
                                      src={`https://www.youtube.com/embed/YRJ6xoiRcpQ`}
                                      title="YouTube video player"
                                      frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen
                                      style={{
                                        width: isSmallScreen ? "100%" : "100%",
                                        maxWidth: isSmallScreen
                                          ? "100%"
                                          : "100%",
                                        borderRadius: "20px",
                                      }}
                                    ></iframe>

                                    {!showIcon && (
                                      <img
                                        onClick={handlePlayClick}
                                        src="/images/video-play-icon.svg"
                                        alt=""
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                          cursor: "pointer",
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              )
                            )}
                          </Box>
                        </Box>
                      ) : null}
                    </Box>
                  ))}

                <Box display="flex" flexDirection="column" padding="10px">
                  <Box
                    display="flex"
                    alignItems="center"
                    // borderBottom={`1px dashed ${themeColor?.color || "#FFA845"}`}
                    paddingBottom="10px"
                    gap="10px"
                    color="#000"
                    fontSize={isSmallScreen ? "16px" : "18px"}
                    fontWeight={600}
                    marginTop="20px"
                  >
                    <img
                      src="/images/book-call-icon.svg"
                      alt=""
                      style={{
                        width: isSmallScreen ? "30px" : "34px",
                        height: isSmallScreen ? "30px" : "34px",
                      }}
                    />
                    <div>Form</div>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent={isSmallScreen ? "center" : "start"}
                    alignItems="center"

                    // padding="5px"
                    // marginTop="10px"
                  >
                    <Box
                      sx={{
                        borderRadius: "10px",
                        padding: "20px",
                        backgroundColor: "#fff",
                        // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        width: "100%",
                        maxWidth: isSmallScreen ? "none" : "100%",
                      }}
                      boxShadow={"2px 2px 5px gray"}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        marginBottom="10px"
                      >
                        Join our community
                      </Typography>
                      <Typography variant="body2" marginBottom="20px">
                        Please fill all the formality
                      </Typography>

                      <Formik
                        initialValues={{
                          name: "",
                          email: "",
                          userName: type || "",
                        }}
                        validationSchema={joinValidation}
                        onSubmit={async (values, { resetForm }) => {
                          try {
                            const res = await axios.post(
                              "https://btapped.bverselabs.com/api/forms",
                              values,
                              {
                                headers: {
                                  "Content-type":
                                    "application/json; charset=UTF-8",
                                },
                              }
                            );
                            if (res?.data?.isSuccess) {
                              toster.success("Form submitted successfully");
                              resetForm();
                            }
                          } catch (error) {
                            console.error("Error submitting form:", error);
                          }
                        }}
                      >
                        <Form>
                          <Box
                            display="flex"
                            flexDirection={isSmallScreen ? "column" : "row"}
                            gap="10px"
                          >
                            <Box width={"100%"}>
                              <Typography paddingBottom={"2px"} variant="body2">
                                Name
                              </Typography>
                              <Field
                                type="text"
                                name="name"
                                placeholder="Name"
                                className="input"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                }}
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error-message"
                                style={{ color: "red", fontSize: "12px" }}
                              />
                            </Box>
                          </Box>

                          <Box width={"100%"}>
                            <Typography paddingBottom={"2px"} variant="body2">
                              Email
                            </Typography>
                            <Field
                              type="email"
                              name="email"
                              placeholder="Email"
                              className="input"
                              style={{
                                width: "100%",
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                              }}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error-message"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </Box>

                          <button
                            className="theme-btn"
                            type="submit"
                            style={{
                              width: "auto",
                              padding: "10px",
                              borderRadius: "5px",
                              border: "none",
                              backgroundColor: { color },
                              // backgroundColor: '#FFA845',
                              color: "#fff",
                              cursor: "pointer",
                              fontSize: isSmallScreen ? "14px" : "16px",
                            }}
                          >
                            Join
                          </button>
                        </Form>
                      </Formik>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MobileView;
