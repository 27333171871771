import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Card = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [colors, setColors] = useState([]);
    const [data, setData] = useState(null);
    const [themeColor, setThemeColors] = useState([]);
    const [links, setLinks] = useState([]);

    const iconsMapping = {
        'phone': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29.419 29.416">
                <path id="Path_3637" data-name="Path 3637" d="M896.485,406.939c6.177,6.175,7.578-.968,11.511,2.962,3.791,3.791,5.971,4.549,1.166,9.353-.6.483-4.424,6.3-17.86-7.123s-7.623-17.262-7.14-17.863c4.816-4.816,5.562-2.632,9.353,1.166C897.448,399.358,890.309,400.764,896.485,406.939Z" transform="translate(-882.501 -391.503)" fill="#51cd5f" fill-rule="evenodd" />
            </svg>),
        'whatsapp': (
            <svg id="whatsapp_2_" data-name="whatsapp (2)" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 31.558 31.699">
                <defs>
                    <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#20b038" />
                        <stop offset="1" stop-color="#60d66a" />
                    </linearGradient>
                </defs>
                <path id="Path_16191" data-name="Path 16191" d="M26.96,4.607A15.714,15.714,0,0,0,2.229,23.56L0,31.7l8.33-2.184a15.7,15.7,0,0,0,7.509,1.912h.007A15.714,15.714,0,0,0,26.96,4.607ZM15.845,28.774H15.84a13.044,13.044,0,0,1-6.647-1.82l-.477-.283-4.943,1.3,1.32-4.818-.311-.494a13.061,13.061,0,1,1,11.064,6.119Z" fill="#e0e0e0" />
                <path id="Path_16192" data-name="Path 16192" d="M27.875,51.329,30,43.561A15,15,0,1,1,43,51.069h-.007a14.99,14.99,0,0,1-7.165-1.824Z" transform="translate(-27.154 -20.533)" fill="url(#linear-gradient)" />
                <path id="Path_16193" data-name="Path 16193" d="M311.3,323.707c-.291-.646-.6-.659-.873-.671-.226-.01-.485-.009-.744-.009a1.426,1.426,0,0,0-1.035.486,4.351,4.351,0,0,0-1.359,3.237,7.55,7.55,0,0,0,1.585,4.014c.194.259,2.685,4.3,6.63,5.858,3.278,1.293,3.946,1.036,4.657.971a3.92,3.92,0,0,0,2.62-1.845,3.243,3.243,0,0,0,.226-1.845c-.1-.162-.356-.259-.744-.453s-2.3-1.133-2.652-1.263-.615-.194-.873.194-1,1.262-1.229,1.521-.453.292-.841.1a10.615,10.615,0,0,1-3.121-1.926,11.688,11.688,0,0,1-2.159-2.688c-.226-.388-.024-.6.17-.792.174-.174.388-.453.582-.68a2.66,2.66,0,0,0,.388-.647.714.714,0,0,0-.032-.68C312.4,326.394,311.645,324.475,311.3,323.707Z" transform="translate(-299.337 -314.669)" fill="#fff" fill-rule="evenodd" />
                <path id="Path_16194" data-name="Path 16194" d="M33.65,4.668A15.534,15.534,0,0,0,9.2,23.4L7,31.45l8.234-2.159a15.522,15.522,0,0,0,7.422,1.89h.007A15.533,15.533,0,0,0,33.65,4.668ZM22.663,28.558h-.005a12.894,12.894,0,0,1-6.571-1.8l-.472-.28L10.729,27.76,12.034,23l-.307-.488a12.911,12.911,0,1,1,10.937,6.048Z" transform="translate(-6.819 -0.111)" fill="#fff" />
            </svg>),
        "linkedin": (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 31.229 21.469">
                <path id="Path_12470" data-name="Path 12470" d="M0,5H6.983V26.469H0Zm26.005.252c-.074-.023-.144-.049-.223-.07q-.141-.032-.283-.055A6.259,6.259,0,0,0,24.247,5c-4.071,0-6.654,2.961-7.5,4.1V5H9.759V26.469h6.983V14.759s5.278-7.35,7.5-1.952V26.469h6.981V11.981a6.964,6.964,0,0,0-5.223-6.73Z" transform="translate(0 -5)" fill="#1976d2" />
            </svg>),
        'location': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29.438 34.948">
                <path id="Path_3669" data-name="Path 3669" d="M155.5,349.537a14.719,14.719,0,1,1,29.439,0,17.949,17.949,0,0,1-3.863,10.9,38.2,38.2,0,0,1-9.594,8.9,2.078,2.078,0,0,1-2.527,0,37.5,37.5,0,0,1-9.594-8.9,17.948,17.948,0,0,1-3.862-10.9Zm9.86.448a4.859,4.859,0,1,0,4.859-4.859A4.859,4.859,0,0,0,165.36,349.986Z" transform="translate(-155.5 -334.818)" fill="#f95041" fill-rule="evenodd" />
            </svg>),
        'snapchat': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 35.605 33.442">
                <path id="snapchat" d="M33.925,24.225c-4.626-.761-6.711-5.5-6.8-5.7l-.019-.039a1.43,1.43,0,0,1-.162-1.177c.253-.593,1.222-.9,1.862-1.1.186-.058.361-.115.506-.171a3.916,3.916,0,0,0,1.38-.824,1.31,1.31,0,0,0,.42-.943A1.484,1.484,0,0,0,30,12.981a2.011,2.011,0,0,0-.753-.143,1.676,1.676,0,0,0-.7.143,3.933,3.933,0,0,1-1.443.411,1.324,1.324,0,0,1-.478-.1l.041-.668.006-.1c.149-2.342.333-5.256-.441-6.986A9.4,9.4,0,0,0,24.119,2.5,8.854,8.854,0,0,0,21.586.835,10.007,10.007,0,0,0,17.635,0h-.087l-.595.006A10.045,10.045,0,0,0,13,.841a8.8,8.8,0,0,0-2.524,1.667A9.428,9.428,0,0,0,8.365,5.532c-.774,1.728-.59,4.641-.443,6.981v0l.048.768a1.384,1.384,0,0,1-.567.106,3.752,3.752,0,0,1-1.542-.413,1.384,1.384,0,0,0-.586-.121,2.072,2.072,0,0,0-1.036.29,1.433,1.433,0,0,0-.733.936A1.292,1.292,0,0,0,4,15.3a4.253,4.253,0,0,0,1.289.738c.147.058.322.112.506.171.64.2,1.611.51,1.864,1.1a1.44,1.44,0,0,1-.164,1.177l-.017.039a11.452,11.452,0,0,1-1.743,2.695,9.782,9.782,0,0,1-2.1,1.856A7.736,7.736,0,0,1,.679,24.223.807.807,0,0,0,0,25.067a1.025,1.025,0,0,0,.084.353v0a2.162,2.162,0,0,0,1.172,1,13.387,13.387,0,0,0,3.385.88,4.106,4.106,0,0,1,.208.744c.056.26.115.528.2.811a.887.887,0,0,0,.928.675,4.517,4.517,0,0,0,.8-.11,9.051,9.051,0,0,1,1.815-.205,7.885,7.885,0,0,1,1.3.11A6.163,6.163,0,0,1,12.31,30.58a7.85,7.85,0,0,0,4.78,1.862l.175-.006c.069,0,.158.006.249.006a7.862,7.862,0,0,0,4.78-1.86l0,0a6.186,6.186,0,0,1,2.424-1.25,7.886,7.886,0,0,1,1.3-.11,9.093,9.093,0,0,1,1.815.192,4.385,4.385,0,0,0,.8.1h.039a.876.876,0,0,0,.889-.664q.114-.4.2-.8a4.248,4.248,0,0,1,.205-.74,13.48,13.48,0,0,0,3.385-.88,2.17,2.17,0,0,0,1.17-1,1.027,1.027,0,0,0,.087-.357.8.8,0,0,0-.677-.839Z" transform="translate(0.501 0.5)" fill="#ffeb3b" stroke="#fff" stroke-width="1" />
            </svg>),
        'twitter': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 39.092 31.762">
                <path id="twitter" d="M39.092,5.76a16.709,16.709,0,0,1-4.618,1.266A7.97,7.97,0,0,0,38,2.6a16.015,16.015,0,0,1-5.082,1.94,8.014,8.014,0,0,0-13.863,5.48,8.252,8.252,0,0,0,.186,1.828A22.683,22.683,0,0,1,2.722,3.461,8.026,8.026,0,0,0,5.185,14.172a7.916,7.916,0,0,1-3.621-.987v.088a8.05,8.05,0,0,0,6.421,7.874,8,8,0,0,1-2.1.264,7.085,7.085,0,0,1-1.517-.137,8.09,8.09,0,0,0,7.488,5.583,16.1,16.1,0,0,1-9.937,3.418A15.01,15.01,0,0,1,0,30.165a22.561,22.561,0,0,0,12.294,3.6c14.747,0,22.81-12.216,22.81-22.805,0-.354-.012-.7-.029-1.036A15.988,15.988,0,0,0,39.092,5.76Z" transform="translate(0 -2)" fill="#20a1ee" />
            </svg>),
        'instagram': (
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 29.723 29.723">
                <defs>
                    <radialGradient id="radial-gradient" cx="0.065" cy="0.978" r="1.27" gradientUnits="objectBoundingBox">
                        <stop offset="0.09" stop-color="#fa8f21" />
                        <stop offset="0.78" stop-color="#d82d7e" />
                    </radialGradient>
                    <radialGradient id="radial-gradient-2" cx="0.693" cy="0.946" r="1.117" gradientUnits="objectBoundingBox">
                        <stop offset="0.64" stop-color="#8c3aaa" stop-opacity="0" />
                        <stop offset="1" stop-color="#8c3aaa" />
                    </radialGradient>
                </defs>
                <g id="Group_67186" data-name="Group 67186" transform="translate(15116.229 1463.229)">
                    <path id="Path_16" data-name="Path 16" d="M7.537,29.617a10.007,10.007,0,0,1-3.367-.623,5.632,5.632,0,0,1-2.085-1.356A5.589,5.589,0,0,1,.728,25.554a10.007,10.007,0,0,1-.622-3.366C.018,20.277,0,19.7,0,14.861S.02,9.448.105,7.536A10.063,10.063,0,0,1,.728,4.17,5.653,5.653,0,0,1,2.085,2.085,5.6,5.6,0,0,1,4.17.728,10.007,10.007,0,0,1,7.536.105C9.448.018,10.022,0,14.861,0s5.413.02,7.326.105a10.063,10.063,0,0,1,3.366.623,5.611,5.611,0,0,1,2.085,1.356A5.614,5.614,0,0,1,28.994,4.17a10.007,10.007,0,0,1,.624,3.367c.087,1.912.105,2.485.105,7.326s-.018,5.413-.105,7.326a10.053,10.053,0,0,1-.624,3.366,6,6,0,0,1-3.441,3.44,10.007,10.007,0,0,1-3.367.624c-1.911.087-2.485.105-7.326.105s-5.413-.017-7.324-.105" transform="translate(-15116.229 -1463.229)" fill="url(#radial-gradient)" />
                    <path id="Path_17" data-name="Path 17" d="M7.537,29.617a10.007,10.007,0,0,1-3.367-.623,5.632,5.632,0,0,1-2.085-1.356A5.589,5.589,0,0,1,.728,25.554a10.007,10.007,0,0,1-.622-3.366C.018,20.277,0,19.7,0,14.861S.02,9.448.105,7.536A10.063,10.063,0,0,1,.728,4.17,5.653,5.653,0,0,1,2.085,2.085,5.6,5.6,0,0,1,4.17.728,10.007,10.007,0,0,1,7.536.105C9.448.018,10.022,0,14.861,0s5.413.02,7.326.105a10.063,10.063,0,0,1,3.366.623,5.611,5.611,0,0,1,2.085,1.356A5.614,5.614,0,0,1,28.994,4.17a10.007,10.007,0,0,1,.624,3.367c.087,1.912.105,2.485.105,7.326s-.018,5.413-.105,7.326a10.053,10.053,0,0,1-.624,3.366,6,6,0,0,1-3.441,3.44,10.007,10.007,0,0,1-3.367.624c-1.911.087-2.485.105-7.326.105s-5.413-.017-7.324-.105" transform="translate(-15116.229 -1463.229)" fill="url(#radial-gradient-2)" />
                    <path id="Path_18" data-name="Path 18" d="M443.212,450.563a3.681,3.681,0,1,1,3.681,3.681,3.681,3.681,0,0,1-3.681-3.681m-1.99,0a5.67,5.67,0,1,0,5.67-5.67,5.67,5.67,0,0,0-5.67,5.67m10.239-5.895a1.325,1.325,0,1,0,1.326-1.324h0a1.326,1.326,0,0,0-1.325,1.324m-9.03,14.883a6.1,6.1,0,0,1-2.051-.379,3.656,3.656,0,0,1-2.1-2.1,6.1,6.1,0,0,1-.379-2.051c-.054-1.164-.064-1.514-.064-4.462s.012-3.3.064-4.462a6.143,6.143,0,0,1,.379-2.051,3.654,3.654,0,0,1,2.1-2.1,6.1,6.1,0,0,1,2.051-.379c1.164-.054,1.513-.064,4.461-.064s3.3.011,4.462.064a6.143,6.143,0,0,1,2.051.379,3.654,3.654,0,0,1,2.1,2.1,6.1,6.1,0,0,1,.379,2.051c.054,1.166.064,1.514.064,4.462s-.011,3.3-.064,4.462a6.126,6.126,0,0,1-.379,2.051,3.656,3.656,0,0,1-2.1,2.1,6.1,6.1,0,0,1-2.051.379c-1.164.054-1.514.064-4.462.064s-3.3-.011-4.461-.064m-.091-19.963a8.1,8.1,0,0,0-2.681.513,5.645,5.645,0,0,0-3.23,3.23,8.1,8.1,0,0,0-.513,2.681c-.054,1.177-.067,1.554-.067,4.552s.013,3.375.067,4.552a8.1,8.1,0,0,0,.513,2.681,5.645,5.645,0,0,0,3.23,3.23,8.1,8.1,0,0,0,2.681.513c1.178.054,1.554.067,4.552.067s3.375-.013,4.552-.067a8.1,8.1,0,0,0,2.681-.513,5.645,5.645,0,0,0,3.23-3.23,8.076,8.076,0,0,0,.513-2.681c.054-1.178.066-1.554.066-4.553s-.013-3.375-.066-4.552a8.1,8.1,0,0,0-.513-2.681,5.647,5.647,0,0,0-3.229-3.23,8.084,8.084,0,0,0-2.681-.513c-1.178-.054-1.554-.067-4.552-.067s-3.375.013-4.553.067" transform="translate(-15548.229 -1898.868)" fill="#fff" />
                </g>
            </svg>
        ),
        'facebook': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16.005 30.109">
                <path id="Path_12467" data-name="Path 12467" d="M316.956,216.808l.829-5.408H312.6v-3.509a2.7,2.7,0,0,1,3.049-2.922H318v-4.6a28.77,28.77,0,0,0-4.188-.365c-4.273,0-7.066,2.59-7.066,7.279V211.4H302v5.408h4.75v13.073a18.906,18.906,0,0,0,5.846,0V216.808Z" transform="translate(-302 -200)" fill="#1877f2" />
            </svg>
        ),
        'email': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 37.833 24.321">
                <path id="Path_40069" data-name="Path 40069" d="M43.011,34.954a2.664,2.664,0,0,1-1.353.367H9.175a2.664,2.664,0,0,1-1.353-.367L18.661,24.116l2.675,2.675a5.538,5.538,0,0,0,4.081,1.549A5.538,5.538,0,0,0,29.5,26.791l2.675-2.675ZM43.966,34,33.128,23.161,43.966,12.322a2.664,2.664,0,0,1,.367,1.353V32.646A2.664,2.664,0,0,1,43.966,34Zm-37.1,0A2.663,2.663,0,0,1,6.5,32.646V13.675a2.663,2.663,0,0,1,.367-1.353L17.705,23.161ZM43.011,11.367,28.542,25.836a4.2,4.2,0,0,1-3.125,1.153,4.2,4.2,0,0,1-3.125-1.153L7.822,11.367A2.664,2.664,0,0,1,9.175,11H41.658A2.664,2.664,0,0,1,43.011,11.367Z" transform="translate(-6.5 -11)" fill="#4a67ed" fill-rule="evenodd" />
            </svg>
        ),
        'contact': (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30.027 32.337">
                <path id="contact" d="M29.717,2H6.62A2.309,2.309,0,0,0,4.31,4.31V6.62H3.155a1.155,1.155,0,0,0,0,2.31H6.62a1.155,1.155,0,1,1,0,2.31H4.31V25.1H3.155a1.155,1.155,0,0,0,0,2.31H6.62a1.155,1.155,0,1,1,0,2.31H4.31v2.31a2.309,2.309,0,0,0,2.31,2.31h23.1a2.309,2.309,0,0,0,2.31-2.31V4.31A2.309,2.309,0,0,0,29.717,2ZM18.168,7.774a4.62,4.62,0,1,1-4.62,4.62A4.62,4.62,0,0,1,18.168,7.774ZM25.1,27.408H11.239V25.1a6.929,6.929,0,1,1,13.859,0Z" transform="translate(-2 -2)" fill="#109afa" />
            </svg>
        ),
        "office": (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29.717 29.716">
                <path id="company" d="M31.934,31.152H29.588V10.038a.766.766,0,0,0-.532-.782L23.332,6.863V31.152H21.768V3.782A.782.782,0,0,0,20.986,3H6.91a.782.782,0,0,0-.782.782v27.37H3.782a.782.782,0,1,0,0,1.564H31.934a.782.782,0,1,0,0-1.564ZM25.678,12.384h1.564a.782.782,0,0,1,.782.782.8.8,0,0,1-.782.782H25.678a.8.8,0,0,1-.782-.782A.782.782,0,0,1,25.678,12.384Zm0,4.692h1.564a.782.782,0,1,1,0,1.564H25.678a.782.782,0,0,1,0-1.564Zm0,4.692h1.564a.8.8,0,0,1,.782.782.782.782,0,0,1-.782.782H25.678a.782.782,0,0,1-.782-.782.8.8,0,0,1,.782-.782Zm0,4.692h1.564a.782.782,0,1,1,0,1.564H25.678a.782.782,0,0,1,0-1.564ZM15.512,7.692h1.564a.782.782,0,1,1,0,1.564H15.512a.782.782,0,0,1,0-1.564Zm0,4.692h1.564a.782.782,0,1,1,0,1.564H15.512a.782.782,0,1,1,0-1.564Zm0,4.692h1.564a.782.782,0,1,1,0,1.564H15.512a.782.782,0,1,1,0-1.564Zm0,4.692h1.564a.782.782,0,1,1,0,1.564H15.512a.782.782,0,1,1,0-1.564ZM10.726,7.692h1.658a.782.782,0,0,1,0,1.564H10.82a.782.782,0,0,1,0-1.564Zm0,4.692h1.658a.782.782,0,1,1,0,1.564H10.82a.782.782,0,1,1,0-1.564Zm0,4.692h1.658a.782.782,0,1,1,0,1.564H10.82a.782.782,0,1,1,0-1.564Zm0,4.692h1.658a.782.782,0,1,1,0,1.564H10.82a.782.782,0,1,1,0-1.564Zm4,9.384V28.024H13.166v3.128H11.6V27.2a.782.782,0,0,1,.782-.735h3.128a.782.782,0,0,1,.782.782v3.91Z" transform="translate(-3 -3)" fill="#7cce5c" />
            </svg>
        ),
        "website": (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32.153 32.154">
                <path id="globe" d="M18.958,3.025V9.436H13.365A19.469,19.469,0,0,1,18.1,3.069C18.384,3.043,18.672,3.037,18.958,3.025Zm1.289,0c.287.013.576.017.858.044a19.437,19.437,0,0,1,4.748,6.368H20.247Zm-4.413.428a21.3,21.3,0,0,0-3.9,5.984H6.734a16.078,16.078,0,0,1,9.1-5.984Zm7.534,0a16.077,16.077,0,0,1,9.1,5.984H27.283a21.285,21.285,0,0,0-3.914-5.984ZM5.859,10.725h5.554a24.156,24.156,0,0,0-1.459,7.732H3.525a16,16,0,0,1,2.333-7.732Zm6.962,0h6.137v7.732H11.243a22.72,22.72,0,0,1,1.577-7.732Zm7.426,0h6.147a22.713,22.713,0,0,1,1.577,7.732H20.247Zm7.552,0h5.547a16,16,0,0,1,2.333,7.732H29.26A24.155,24.155,0,0,0,27.8,10.725ZM3.524,19.746H9.953a24.151,24.151,0,0,0,1.459,7.732H5.857a16.007,16.007,0,0,1-2.331-7.732Zm7.718,0h7.716v7.732H12.821a22.721,22.721,0,0,1-1.577-7.732Zm9,0h7.692a22.75,22.75,0,0,1-1.956,7.732H20.247Zm8.982,0h6.45a16.007,16.007,0,0,1-2.331,7.732H27.381a23.559,23.559,0,0,0,1.848-7.732ZM6.734,28.767h5.2a21.3,21.3,0,0,0,3.9,5.984,16.073,16.073,0,0,1-9.1-5.984Zm6.632,0h5.593v6.411c-.285-.013-.572-.018-.853-.044A19.431,19.431,0,0,1,13.366,28.767Zm6.882,0h5.107a28.515,28.515,0,0,1-4.334,6.373c-.255.023-.515.028-.773.039Zm6.557,0h5.668a16.074,16.074,0,0,1-9.51,6.089A29.976,29.976,0,0,0,26.8,28.767Z" transform="translate(-3.524 -3.025)" fill="#20a1ee" fill-rule="evenodd" />
            </svg>
        ),
        "indeed": (
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="20px" height="20px"><g fill="#0051ff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ mixBlendMode: "normal" }}><g transform="scale(8,8)"><path d="M18.5,2c-7.75,0 -12.5,9.25 -12.5,14c0,0 3.25,-12 13,-12c3.625,0 6,2 6,2c0,0 -1,-4 -6.5,-4zM17.5,7c-1.933,0 -3.5,1.567 -3.5,3.5c0,1.933 1.567,3.5 3.5,3.5c1.933,0 3.5,-1.567 3.5,-3.5c0,-1.933 -1.567,-3.5 -3.5,-3.5zM20,16c0,0 -1,1 -5,1v10.5c0,1.381 1.119,2.5 2.5,2.5c1.381,0 2.5,-1.119 2.5,-2.5z"></path></g></g>
            </svg>
        ),
        "calender": (
            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 28.912 32.154">
                <path id="Path_3668" data-name="Path 3668" d="M232.535,280.244v1.2a7.535,7.535,0,0,1,7.347,7.981l.018,13.526c0,5.037-3.159,8.137-8.232,8.145l-12.393.016a7.673,7.673,0,0,1-8.253-8.219l-.016-13.367a7.529,7.529,0,0,1,7.233-8.063v-1.2a1.222,1.222,0,1,1,2.442,0v1.124l9.413-.013V280.25a1.222,1.222,0,1,1,2.442,0Zm-19.086,9.781,24-.032v-.562c-.069-3.45-1.8-5.26-4.906-5.53v1.236a1.222,1.222,0,1,1-2.442,0v-1.3l-9.413.013v1.3a1.222,1.222,0,1,1-2.442,0v-1.236c-3.09.31-4.8,2.127-4.8,5.607Zm17.207,7.285v.018A1.307,1.307,0,0,0,232,298.611a1.324,1.324,0,1,0-1.348-1.3Zm1.308,7.2a1.361,1.361,0,0,1-1.318-1.363,1.337,1.337,0,0,1,1.3-1.366h.014a1.365,1.365,0,0,1,0,2.729Zm-7.838-7.179a1.308,1.308,0,1,0,1.3-1.38,1.371,1.371,0,0,0-1.3,1.387Zm1.371,7.106a1.321,1.321,0,0,1-1.365-1.284,1.372,1.372,0,0,1,1.3-1.38,1.332,1.332,0,0,1,.066,2.663Zm-7.9-7.05a1.308,1.308,0,1,0,1.3-1.38,1.368,1.368,0,0,0-1.3,1.387Zm1.371,7.063a1.32,1.32,0,0,1-1.364-1.284,1.371,1.371,0,0,1,1.3-1.38,1.332,1.332,0,0,1,.066,2.663Z" transform="translate(-210.988 -278.984)" fill="#a7a5a5" fill-rule="evenodd" />
            </svg>
        ),
        "video": (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30.35 19.011">
                <path id="Path_40067" data-name="Path 40067" d="M18.49,48.016V37.754c0-2.829,1.531-4.375,4.389-4.375h12.54c2.844,0,4.389,1.545,4.389,4.375V48.016c0,2.829-1.545,4.375-4.389,4.375H22.879c-2.844,0-4.389-1.545-4.389-4.375Zm22.166-8.183,5.513-4.494a2.271,2.271,0,0,1,1.411-.617c.751,0,1.259.522,1.259,1.451V49.957c0,.939-.508,1.451-1.259,1.451a2.213,2.213,0,0,1-1.411-.617l-5.513-4.534Z" transform="translate(-18.49 -33.38)" fill="#a7a5a5" />
            </svg>

        ),
        "home": (
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="20" height="20">
                <g fill="#a7a5a5" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ "mixBlendMode": "normal" }}><g transform="scale(8.53333,8.53333)"><path d="M15,2c-0.26138,0.00002 -0.51237,0.10237 -0.69922,0.28516l-10.9082,8.92187c-0.0126,0.00947 -0.02497,0.01924 -0.03711,0.0293l-0.03711,0.03125v0.00195c-0.20274,0.18887 -0.31802,0.45339 -0.31836,0.73047c0,0.55228 0.44772,1 1,1h1v11c0,1.105 0.895,2 2,2h16c1.105,0 2,-0.895 2,-2v-11h1c0.55228,0 1,-0.44772 1,-1c0.0002,-0.27776 -0.11513,-0.54309 -0.31836,-0.73242l-0.01562,-0.01172c-0.02194,-0.01988 -0.04475,-0.03878 -0.06836,-0.05664l-1.59766,-1.30664v-3.89258c0,-0.552 -0.448,-1 -1,-1h-1c-0.552,0 -1,0.448 -1,1v1.43945l-6.32227,-5.17187c-0.18422,-0.17125 -0.42621,-0.26679 -0.67773,-0.26758zM18,15h4v8h-4z"></path></g></g>
            </svg>
        )
    };


    const handleSave = async () => {
        const parts = data?.cardShareLink?.split('/');
        const userId = parts[parts.length - 1];
        const cardId = parts[parts.length - 2];
        try {
            setLoading(true);
            const respose = await fetch(`https://tapnconnect.codingcopz.in/api/cards/get/shareCard?uniqueId=${id}&isSave=true&cardId=${cardId}&userId=${userId}`);
            const json = await respose.json();
            if (json.isSuccess) {
                localStorage.setItem('response', true);
                setLoading(false);
                navigate('/success', { replace: true });
                return;
            } else {
                localStorage.setItem('response', false);
                setLoading(false);
                navigate('/failed', { state: { message: json?.message }, replace: true });
                return;
            }
        } catch (error) {
            setLoading(false);
            navigate('/failed', { replace: true });
            return;
        }
    }

    const validateId = async () => {
        if (id) {
            try {
                const response = await fetch(`https://tapnconnect.codingcopz.in/api/cards/get/shareCard?uniqueId=${id}`);
                const card = await response.json();
                if (card?.isSuccess) {
                    setData(card?.data);
                    setColors(card?.data?.themeColors);
                    localStorage.setItem('id', id);
                    localStorage.setItem('data', JSON.stringify(card?.data));
                    localStorage.setItem('key', JSON.stringify(card?.data?.isEnableInfoCollection));
                    try {
                        const response = await fetch(`https://tapnconnect.codingcopz.in/api/links?cardId=${card?.data?.id}&serverPaging=false`);
                        const data = await response.json();
                        console.log(data?.items);
                        setLinks(data?.items);
                    } catch (error) {
                        console.log(error);
                    }
                    if (card?.data?.isEnableInfoCollection) {
                        navigate('/');
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                    navigate('/404');
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            navigate('/404');
        }
    }
    const arrow = <svg xmlns="http://www.w3.org/2000/svg" width="26.904" height="26.905" viewBox="0 0 26.904 26.905">
        <path id="Union_14" data-name="Union 14" d="M8423.447,3520.311a1,1,0,0,1-.143-1.307l.09-.109,5.92-6.143h-16.383a.976.976,0,0,1-.123-1.937l.123-.005h16.385l-5.922-6.146a1,1,0,0,1-.049-1.326.909.909,0,0,1,1.266-.145l.1.1,7.516,7.8a1,1,0,0,1,.088,1.266h0c-.02.026-.039.051-.059.074l-.029.035-7.516,7.806a.906.906,0,0,1-1.264.039Z" transform="translate(-8425.178 3485.683) rotate(-45)" fill={`#${themeColor[9]}`} />
    </svg>;


    const handleLinkClick = (linkType, link) => {
        console.log(link)
        const message = 'Hello, this is a WhatsApp message!';
        const email = 'recipient@example.com';
        const subject = `${link?.contact} from tapnconnect`;
        const body = 'Testing ABC body';
        const contactData = {
            firstName: 'John',
            lastName: 'Doe',
            phoneNumber: '1234567890',
            email: 'johndoe@example.com',
        };
        const linkSchemas = {
            location: 'your-mobile-app://open/location',
            phone: `tel:${link?.contact}`,
            // twitter: 'twitter://',
            twitter: `twitter://user?screen_name=${link?.contact}`,
            snapchat: `snapchat://add/${link?.contact}`,
            // facebook: 'fb://',
            facebook: `https://www.facebook.com/${link?.contact}`,
            instagram: `http://instagram.com/_u/${link?.contact}/`,
            whatsapp: `https://wa.me/+91${link?.contact}?text=${encodeURIComponent(message)}`,
            // whatsapp: `whatsapp://send?text=${encodeURIComponent(message)}`,
            indeed: `indeed://`,
            email: `mailto:${null}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`,
            contact: `contacts://add?firstName=${encodeURIComponent(contactData.firstName)}&lastName=${encodeURIComponent(contactData.lastName)}&phoneNumbers=${encodeURIComponent(contactData.phoneNumber)}&emails=${encodeURIComponent(contactData.email)}`
        };
        if (linkSchemas[linkType]) {
            if (linkType === 'twitter') {
                if (window.navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
                    window.location.href = linkSchemas[linkType];
                } else if (window.navigator.userAgent.match(/Android/)) {
                    window.location.href = `intent:${linkSchemas[linkType]}#Intent;package=com.twitter.android;scheme=${linkSchemas[linkType]};end`;
                } else {
                    window.open('https://www.twitter.com/', '_blank');
                }
            }
            else if (linkType === 'snapchat') {
                // const message = 'Ooh, This is new message';
                // const textArea = document.createElement("textarea");
                // textArea.value = message;
                // document.body.appendChild(textArea);
                // textArea.select();
                // document.execCommand("copy");
                // document.body.removeChild(textArea);
                if (window.navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
                    window.location.href = 'https://www.snapchat.com/';
                } else if (window.navigator.userAgent.match(/Android/)) {
                    window.location.href = linkSchemas[linkType];
                } else {
                    window.open('https://www.snapchat.com/', '_blank');
                }
            }
            else if (linkType === 'facebook') {
                if (window.navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
                    window.location.href = linkSchemas[linkType];
                } else if (window.navigator.userAgent.match(/Android/)) {
                    const isFacebookAppInstalled = window.navigator.userAgent.includes('FBAN');
                    if (isFacebookAppInstalled) {
                        window.location.href = `fb://profile/1234567890`;
                    } else {
                        window.location.href = 'https://play.google.com/store/apps/details?id=com.facebook.katana';
                    }
                } else {
                    window.open(linkSchemas[linkType], '_blank');
                }
            }
            else if (linkType === 'instagram') {
                if (window.navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
                    window.location.href = linkSchemas[linkType];
                } else if (window.navigator.userAgent.match(/Android/)) {
                    // const isInstagramAppInstalled = window.navigator.userAgent.includes('Instagram');
                    // if (isInstagramAppInstalled) {
                    //     window.location.href = linkSchemas[linkType];
                    // } else {
                    //     window.location.href = 'https://play.google.com/store/apps/details?id=com.instagram.android';
                    // }
                    // window.location.href = `intent:${linkSchemas[linkType]}#Intent;package=com.instagram.android;scheme=${linkSchemas[linkType]};end`;
                    window.location.href = linkSchemas[linkType];
                } else {
                    window.open(`https://www.instagram.com/`, '_blank');
                }
            }
            else if (linkType === 'location') {
                const latitude = 37.7749;
                const longitude = -122.4194;
                window.open(`https://maps.google.com/maps?q=${latitude},${longitude}`, '_blank');
            }
            else if (linkType === 'whatsapp') {
                if (window.navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
                    window.open(`whatsapp://send?phone=${link?.contact}&text=${encodeURIComponent(message)}`);
                } else if (window.navigator.userAgent.match(/Android/)) {
                    window.open(linkSchemas[linkType]);
                } else {
                    window.open(`https://web.whatsapp.com/send?phone=${link?.contact}&text=${encodeURIComponent(message)}`);
                }
            }
            else if (linkType === 'email') {
                window.open(linkSchemas[linkType]);
            }
            else if (linkType === 'indeed') {
                if (window.navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
                    window.open(linkSchemas[linkType]);
                } else if (window.navigator.userAgent.match(/Android/)) {
                    // const isIndeedAppInstalled = window.navigator.userAgent.includes('Indeed');
                    // if (isIndeedAppInstalled) {
                    //     window.open(linkSchemas[linkType]);
                    // } else {
                    //     window.open('https://play.google.com/store/apps/details?id=com.indeed.android.jobsearch', '_blank');
                    // }
                    const intentURL = `intent://www.indeed.com/resume/${link?.contact}#Intent;scheme=https;package=com.indeed.android.jobsearch;end`;
                    if (window.navigator.userAgent.match(/Android/)) {
                        window.location.href = intentURL;
                    } else {
                        window.open(`https://www.indeed.com/resume/${link?.contact}`, '_blank');
                    }
                } else {
                    window.open(`https://www.indeed.com/resume/${link?.contact}`, '_blank');
                }
            }
            else if (linkType === 'contact') {
                const vCardData = `BEGIN:VCARD
                VERSION:3.0
                FN:${contactData.firstName} ${contactData.lastName}
                TEL:${contactData.phoneNumber}
                EMAIL:${contactData.email}
                END:VCARD`;
                const vCardURI = `data:text/vcard;charset=utf-8,${encodeURIComponent(vCardData)}`;
                const downloadLink = document.createElement('a');
                downloadLink.href = vCardURI;
                downloadLink.download = 'contact.vcf';
                downloadLink.click();
            }
            else {
                window.location.href = linkSchemas[linkType];
            }

        } else {
            console.error(`Unsupported link type: ${linkType}`);
        }
    };


    useEffect(() => {
        validateId();
    }, []);


    useEffect(() => {
        if (colors.length > 0) {
            const filtertedColors = colors.map((color) => color.slice(4))
            setThemeColors(filtertedColors);
        }
    }, [colors]);

    if (!loading && themeColor.length > 0) {
        return (
            <div className="screen">
                <div className="card-wrapper">
                    <div className="avatar">
                        <img src={data?.profileImgUrl} alt="avatar" />
                    </div>
                    <div className="card">
                        <div className="card-header" style={{ background: `#${themeColor[0]}` }}>
                            <span style={{ color: `#${themeColor[1]}` }}>tapnconnect.com/{data?.uniqueId}</span>
                        </div>
                        <div className="card-body-wrapper" style={{ background: `#${themeColor[5]}` }} >
                            <div className="card-body" style={{ borderColor: `#${themeColor[12]}` }}>
                                {/* Bg Img */}
                                <div className="card-body-bg">
                                    <img src={data?.coverImgUrl} alt="background" />
                                </div>
                                <div className='card-body-content' style={{ background: `#${themeColor[6]}` }}>
                                    {/* Information */}
                                    <div className="card-body-details">
                                        <div className="typography">
                                            <div className="name-heading">
                                                <span className="name" style={{ color: `#${themeColor[2]}` }}>{data?.fullName}</span>
                                                <img src="/images/star.png" alt="star-icon" />
                                            </div>
                                            <span className="desc" style={{ color: `#${themeColor[2]}`, textTransform: 'capitalize' }}>{data?.jobTitle} at {data?.organizationName}</span>
                                        </div>
                                    </div>
                                    {/* icons */}
                                    <div className="icons">
                                        {
                                            links.map(link => {
                                                if (link?.type === 'headerView') {
                                                    return (
                                                        <div key={link?.id} style={{ background: '#f4f4f4', width: '28px', height: '28px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4px' }} onClick={() => handleLinkClick(link?.linkType, link)}>
                                                            {iconsMapping[link?.linkType] || null}
                                                        </div>
                                                    )
                                                }
                                                return null;
                                            })
                                        }

                                    </div>

                                    {/* Social Links */}
                                    <div className="social">
                                        {
                                            links.map(link => {
                                                if (link?.type === 'listView') {
                                                    return (
                                                        <div key={link?.id} className="social-link" onClick={() => handleLinkClick(link?.linkType, link)} style={{ background: `#${themeColor[10]}`, color: `#${themeColor[4]}` }}>
                                                            {iconsMapping[link?.linkType] || null}
                                                            <span style={{ textTransform: 'capitalize' }}>{link?.title}</span>
                                                            {arrow}
                                                        </div>
                                                    )
                                                }
                                                return null;
                                            })
                                        }
                                    </div>

                                    {/* Button */}
                                    <div className="btn">
                                        <button onClick={handleSave} style={{ background: `#${themeColor[7]}`, color: `#${themeColor[8]}` }}>
                                            Save to Contact
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return <div className='loading-wrapper'><span className='loader'></span></div>
    }
}

export default Card